import { render, staticRenderFns } from "./LayRight.vue?vue&type=template&id=7f1db070&scoped=true&"
import script from "./LayRight.vue?vue&type=script&lang=js&"
export * from "./LayRight.vue?vue&type=script&lang=js&"
import style0 from "./LayRight.vue?vue&type=style&index=0&id=7f1db070&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7f1db070",
  null
  
)

export default component.exports