<template>
  <div class="page-r">
    <div class="page-r-con">
      <div class="title">Categories</div>
      <div class="tags">
          <div class="tag" @click="goToCategory('news')" >News & Evnets</div>
          <div class="tag" @click="goToCategory('media')">Media Coverage</div>
      </div>
      <div class="title">Recent Posts</div>
      <div class="recent-list" v-if="rightList &&rightList.length>0">
        <div class="recent-item" v-for="(item, index) in rightList" :key="index"  @click="api.goToDetail(item)">
          <div class="text">{{item.title}}</div>
          <div class="time">{{item.publishTime | format}}</div>
        </div>
      </div>
      <!-- <div class="title">Tags</div>
      <div class="tags" v-if="tagList">
        <div class="tag" v-for="(item, index) in tagList" :key="index" @click="goToTag(item.tagInUrl)">
          {{item.tagName}}
        </div>
      </div> -->
      <br>
    </div>
  </div>
</template>

<script>
  import api from '@/api'
  import moment from 'moment'

  export default {
    data() {
      return {
        rightList: [],
        tagList: [],
        api: api,
      }
    },
    created() {
      this.getRecentNewsList()
      this.getTags()
    },
    mounted() {

    },
    filters: {
      format(val) {
        return moment(val).format('MMMM DD, YYYY') // 默认为英文、引入后设置格式
      }
    },
    methods: {
      goToCategory(categoryInUrl) {
        window.open(`/news/category/${categoryInUrl}`, '_self')
      },
      goToTag(tagInUrl) {
        window.open(`/news/tag/${tagInUrl}`, '_self')
      },
      getRecentNewsList() {
        api.getNewsFive().then(res => {
          if (res.status === 200) {
            this.rightList = res.data
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        }).catch(err => {
          console.log(err)
        })
      },
      getTags() {
        api.getTags().then(res => {
          if (res.status === 200) {
            this.tagList = res.data
          } else {
            this.$message({
              message: res.message,
              type: 'warning'
            })
          }
        }).catch(err => {
          console.log(err)
        })
      }
    },
    computed: {}
  }
</script>

<style lang="scss" scoped>
  .page-r {
    /* Background */
    background: #F6F8FA;
    width: 30%;
    box-shadow: inset 1px 0px 0px #D7D9DD;
    padding: 100px 0;

    .page-r-con {
      padding-left: 10%;
      padding-right: 10%;

      .title {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 2rem;
        line-height: 4rem;
        margin-bottom: 6px;
      }

      .recent-list {
        // padding-bottom: 40px;
        // border-bottom: 1px solid #D7D9DD;
        // margin-bottom: 40px;

        .recent-item {
          padding: 1.3rem;
          margin-bottom: 1.2rem;
          /* white */

          background: #FFFFFF;
          /* Line */

          border: 1px solid #D7D9DD;
          box-sizing: border-box;
          border-radius: 8px;

          .text {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 400;
            font-size: 1.1rem;
            line-height: 1.7rem;
            /* or 150% */

            /* Black */

            color: #000000;
            margin-bottom: 1.5rem;
          }

          .time {
            font-family: Montserrat;
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 1.4rem;
            /* identical to box height, or 150% */

            /* Gray Blue */

            color: #5F6F8F;

          }
        }

      }

      .tags {
        .tag {
          width: auto;
          height: 2.5rem;
          /* white */
          background: #FFFFFF;
          /* Line */

          border: 1px solid #D7D9DD;
          box-sizing: border-box;
          border-radius: 8px;
          margin-right: 0.7rem;
          margin-bottom: 1rem;
          float: left;
          font-family: Montserrat;
          font-style: normal;
          font-weight: 500;
          font-size: 1rem;
          line-height: 2.5rem;
          /* identical to box height, or 150% */

          /* Gray Blue */

          color: #2788F0;
          padding: 0 1.3rem;
          cursor: pointer;

        }
      }
    }

  }
</style>
